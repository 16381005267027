import { Container, Row, Col } from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import Rocket from "../assets/img/rocket4.json";
import Lottie from "lottie-react";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
            <Lottie
              animationData={Rocket}
              style={{
                height: "200px",
              }}
            />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/nikolin-andoni/">
                <img src={navIcon1} alt="Linkedin" height="100%" width="100%"/>
              </a>
              <a href="https://www.facebook.com/nikolin.andoni.3/">
                <img src={navIcon2} alt="Facebook" height="100%" width="100%"/>
              </a>
              <a href="https://www.instagram.com/nikolin_andoni/">
                <img src={navIcon3} alt="Instagram" height="100%" width="100%"/>
              </a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
