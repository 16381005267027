import { Col } from "react-bootstrap";

export const ProjectCard = ({
  title,
  description,
  imgUrl,
  url,
  width,
  height,
}) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} height={height} width={width} />
        <div className="proj-txtx">
          <h3>{title}</h3>
          <span>{description}</span>
          <a href={url}>{url}</a>
        </div>
      </div>
    </Col>
  );
};
