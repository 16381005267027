import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
ReactGA.initialize("G-LD3FKJT6KN");
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>
);

const SendAnalytics = () => {
  ReactGA.gtag("config", "G-LD3FKJT6KN", {
    page_path: window.location.pathname,
  });
};

export { SendAnalytics };

reportWebVitals(SendAnalytics);
